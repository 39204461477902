<template>
  <div v-loading="loading">
    <!-- <ui-card :heading="`${course !== null ? '' : $t('actions.show') + ' ' + $t('models.course')}`" class="mb-8"> -->
    <course-simple v-if="course" :course="course"></course-simple>
    <!-- </ui-card> -->
    <div v-for="mod in modules" :key="mod.id">
      <ui-card :heading="''" class="mb-4">
        <ui-header
            :heading="getTranslation(mod.name)"
            :infoHtml="getTranslation(mod.description)"
        >
          <ui-link
              type="text"
              class="add_new_button"
              :route="{ name: 'lessons-create', params: { moduleid: mod.id } }"
          >{{
              `${$tc ('actions.add_new', 1)} ${$tc ('models.lesson', 1)}`
            }}
          </ui-link
          >
          <ui-link
              class="mr-4 py-1 p09em"
              type="normal"
              size="small"
              uppercase
              :route="{
              name: 'modules-edit',
              params: { courseModuleId: mod.id }
            }"
          >{{
              `${$tc ('actions.edit', 1)} ${$tc ('models.module', 1)}`
            }}
          </ui-link
          >
          <ui-link type="danger" @click="handleOpenDeleteModule(mod)">
            {{ $t ('actions.delete') }}
          </ui-link>
        </ui-header>
        <lesson-list
            :lessons="mod.lessons"
            @onDelete="l => handleOpenDeleteLesson(l, mod)"
        ></lesson-list>
      </ui-card>
    </div>
    <ui-modal
        :text="$t('confirm.delete.course_module.text')"
        :info="$t('confirm.delete.course_module.info')"
        :show="showDeleteModuleModal"
        @confirm="handleDeleteModule"
        @cancel="handleCloseDeleteModule"
    />
    <ui-modal
        :text="$t('confirm.delete.lesson.text')"
        :info="$t('confirm.delete.lesson.info')"
        :show="showDeleteLessonModal"
        @confirm="handleDeleteLesson"
        @cancel="handleCloseDeleteLesson"
    />
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiLink from '@/components/ui/UiLink';
import UiHeader from '@/components/ui/UiHeader';
import UiModal from '@/components/ui/UiModal';
import UiButton from "@/components/ui/UiButton";
import {getCourse, updateCourse, deleteCourse} from '../api';
import {deleteLesson} from '../../lessons/api';
import {getModulesByCourse, deleteModule} from '../../coursemodules/api';
import CourseSimple from '../components/CourseSimple';
import LessonList from '../../lessons/components/LessonList';

export default {
  data() {
    return {
      course: null,
      modules: [],
      loading: false,
      showDeleteModal: false,
      showDeleteModuleModal: false,
      markedModule: null,
      showDeleteLessonModal: false,
      markedLesson: null,
      tempModule: null
    };
  },

  components: {
    UiCard,
    UiLink,
    UiHeader,
    UiModal,
    CourseSimple,
    LessonList
  },

  methods: {
    async getCourse() {
      this.loading = true;
      const course = await getCourse (this.$route.params.id);
      const modules = await getModulesByCourse (this.$route.params.id);
      this.course = course;
      this.modules = modules;
      this.loading = false;
    },

    async handleSubmit() {
      this.loading = true;
      await updateCourse (this.course);
      this.$router.push ({name: 'courses-index'});
      this.loading = false;
    },

    handleDelete() {
      this.showDeleteModal = true;
    },

    async deleteCourse() {
      this.loading = true;
      await deleteCourse (this.course.id);
      this.showDeleteModal = false;
      this.loading = false;
      this.$router.back ();
    },

    handleCancel() {
      this.$router.back ();
    },

    //#region Delete Course Module
    handleOpenDeleteModule(mod) {
      this.showDeleteModuleModal = true;
      this.markedModule = mod;
    },

    handleCloseDeleteModule() {
      this.markedModule = null;
      this.showDeleteModuleModal = false;
    },

    async handleDeleteModule() {
      if (!this.markedModule) {
        return;
      }
      this.loading = true;
      try {
        await deleteModule (this.markedModule.id);
        this.loading = false;
        this.modules = this.modules.filter (
            cm => cm.id !== this.markedModule.id
        );
        this.handleCloseDeleteModule ();
        const msg = {
          message: this.$t ('feedback.delete_success'),
          type: 'success'
        };
        this.$message (msg);
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t ('feedback.delete_failed'),
          type: 'error'
        };
        this.$message (msg);
      }
    },
    //#endregion Delete Course Module

    //#region Delete Lesson
    handleOpenDeleteLesson(lesson, mod) {
      this.showDeleteLessonModal = true;
      this.markedLesson = lesson;
      this.tempModule = mod;
    },

    handleCloseDeleteLesson() {
      this.showDeleteLessonModal = false;
      this.markedLesson = null;
      this.tempModule = null;
    },

    async handleDeleteLesson() {
      if (!this.markedLesson) {
        return;
      }
      this.loading = true;
      try {
        await deleteLesson (this.markedLesson.id);
        this.loading = false;
        this.tempModule.lessons = this.tempModule.lessons.filter (
            l => l.id !== this.markedLesson.id
        );
        this.handleCloseDeleteLesson ();
        const msg = {
          message: this.$t ('feedback.delete_success'),
          type: 'success'
        };
        this.$message (msg);
      } catch (err) {
        this.loading = false;
        const msg = {
          message: this.$t ('feedback.delete_failed'),
          type: 'error'
        };
        this.$message (msg);
        this.handleCloseDeleteLesson ();
      }
    },
    //#endregion Delete Lesson

    getTranslation(transList) {
      const trans = transList.filter (t => t.lang === this.$i18n.locale);
      if (trans.length == 0) return '';
      return trans[0].value;
      // return transList.filter(t => t.lang === this.$i18n.locale)[0].value;
    }
  },

  created() {
    this.getCourse ();
  },

  mounted() {
    this.$store.commit ('changeBreadcrumbs', [
      {name: "models.a_course", link: "/app/courses/"},
      {name: "actions.edit", link: ""}
    ]);
  },
};
</script>

<style scoped>
.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
  margin-right: 1em;
  margin-left: 1em;
}

.p09em {
  padding: 0.9em;
}
</style>