<template>
  <section>
    <ui-card :heading="''" class="mb-4">
      <ui-header
        :heading="getTranslation(course.title)"
        :infoHtml="getTranslation(course.description)"
      >
        <ui-link
          type="text"
          class="add_new_button"
          :route="{ name: 'modules-create', params: { courseId: course.id } }"
          >{{
            `${$tc('actions.add_new', 1)} ${$tc('models.module', 1)}`
          }}</ui-link
        >
      </ui-header>
    </ui-card>
  </section>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiHeader from '@/components/ui/UiHeader';
import UiLink from '@/components/ui/UiLink';

export default {
  props: {
    course: {
      type: Object,
      required: true
    }
  },

  components: {
    UiCard,
    UiHeader,
    UiLink
  },

  methods: {
    getTranslation(transList) {
      return transList.filter(t => t.lang === this.$i18n.locale)[0].value;
    }
  }
};
</script>

<style scoped>
.add_new_button {
  background-color: white;
  border: solid 1px black;
  color: black;
}
</style>